import React from "react"
import { Layout } from "@components/Layout"
import SignIn from "@auth/Signin"

import { SEO } from "@components/SEO"

const LoginPage = () => (
  <Layout version="blank" authEnabled={true}>
    <SEO title="Login" />
    <SignIn />
  </Layout>
)

export default LoginPage
